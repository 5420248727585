@font-face {
  font-family: "iconfont"; /* Project id 4027342 */
  src: url('iconfont.woff2?t=1703482862044') format('woff2'),
       url('iconfont.woff?t=1703482862044') format('woff'),
       url('iconfont.ttf?t=1703482862044') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bofang1:before {
  content: "\e700";
}

.icon-douyin:before {
  content: "\e7af";
}

.icon-Tiktok3:before {
  content: "\e721";
}

.icon-jiantou_shangyiye:before {
  content: "\eb06";
}

.icon-jiantou_xiayiye:before {
  content: "\eb07";
}

.icon-weixin2:before {
  content: "\e636";
}

.icon-31saoma:before {
  content: "\e60c";
}

.icon-saoma:before {
  content: "\e61e";
}

.icon-saomiaoerweima:before {
  content: "\e663";
}

.icon-PPTtubiao:before {
  content: "\e640";
}

.icon-anzhuangbao:before {
  content: "\e6ef";
}

.icon-pdf:before {
  content: "\e740";
}

.icon-file-pdf-fill:before {
  content: "\e62e";
}

.icon-tianmao:before {
  content: "\e757";
}

.icon-kuaishou:before {
  content: "\e8dc";
}

.icon-pay-jingdong:before {
  content: "\e8dd";
}

.icon-tiktok:before {
  content: "\e805";
}

.icon-weishangcheng:before {
  content: "\e61b";
}

.icon-xiazai2:before {
  content: "\e64a";
}

.icon-goodsppecategory:before {
  content: "\e66e";
}

.icon-gantanhao:before {
  content: "\e60b";
}

.icon-31dingwei:before {
  content: "\e60a";
}

.icon-lingdai:before {
  content: "\e6b5";
}

.icon-shaixuan1:before {
  content: "\e650";
}

.icon-qiyeyuanjing:before {
  content: "\e668";
}

.icon-shiming:before {
  content: "\e66a";
}

.icon-rencailinian:before {
  content: "\e667";
}

.icon-hexinjiazhiguan:before {
  content: "\e615";
}

.icon-youxiang2:before {
  content: "\e65e";
}

.icon-TELEPHONE:before {
  content: "\ea48";
}

.icon-daohang1:before {
  content: "\e651";
}

.icon-24gl-appsBig2:before {
  content: "\e9b7";
}

.icon-xinghao:before {
  content: "\e63f";
}

.icon-sousuofangda:before {
  content: "\ec32";
}

.icon-bofang:before {
  content: "\e87c";
}

.icon-suoshuzhiwei:before {
  content: "\e622";
}

.icon-didian1:before {
  content: "\e609";
}

.icon-lianxiren:before {
  content: "\e614";
}

.icon-guanjianci:before {
  content: "\e607";
}

.icon-shijian:before {
  content: "\e64d";
}

.icon-shu:before {
  content: "\e70e";
}

.icon-redian:before {
  content: "\e608";
}

.icon-wj-fa:before {
  content: "\e728";
}

.icon-iconfonticonliebiao1:before {
  content: "\e617";
}

.icon-yijicaidan:before {
  content: "\e618";
}

.icon-zijianxiangmu:before {
  content: "\e60e";
}

.icon-diqiu:before {
  content: "\e642";
}

.icon-jiangbei:before {
  content: "\e606";
}

.icon-yidongPOSzhongduan:before {
  content: "\e6c0";
}

.icon-loukongguanli:before {
  content: "\e6e4";
}

.icon-kejichengguo:before {
  content: "\e669";
}

.icon-fanhui:before {
  content: "\e60d";
}

.icon-chakan:before {
  content: "\e605";
}

.icon-xiazai1:before {
  content: "\e635";
}

.icon-wuliaoxilie:before {
  content: "\e649";
}

.icon-chanpin:before {
  content: "\e64f";
}

.icon-qingkonghuancun:before {
  content: "\e665";
}

.icon-folderOpen:before {
  content: "\eabe";
}

.icon-shaixuan:before {
  content: "\e613";
}

.icon-danganziliao-qitaziliao:before {
  content: "\e626";
}

.icon-wodezixun:before {
  content: "\e717";
}

.icon-liuyanban-05:before {
  content: "\e630";
}

.icon-yidongyunkongzhitaiicon42:before {
  content: "\e74f";
}

.icon-weixin1:before {
  content: "\e61a";
}

.icon-kefu:before {
  content: "\e734";
}

.icon-dianpu:before {
  content: "\e895";
}

.icon-lianxishouhou:before {
  content: "\e638";
}

.icon-shouhou:before {
  content: "\e63c";
}

.icon-changjianwenti:before {
  content: "\e648";
}

.icon-select:before {
  content: "\e813";
}

.icon-shouji:before {
  content: "\e602";
}

.icon-youxiang1:before {
  content: "\e612";
}

.icon-iconfont-yanzhengma-:before {
  content: "\e694";
}

.icon-lock-s:before {
  content: "\e637";
}

.icon-renyuan:before {
  content: "\e652";
}

.icon-xiazai:before {
  content: "\e65d";
}

.icon-shuangjiantou-you:before {
  content: "\e662";
}

.icon-rili:before {
  content: "\e62a";
}

.icon-daohang:before {
  content: "\e634";
}

.icon-2lianxikefu:before {
  content: "\e603";
}

.icon-dianhua:before {
  content: "\e616";
}

.icon-tubiao-baojia:before {
  content: "\e619";
}

.icon-ceshishenqing:before {
  content: "\e604";
}

.icon-liuyan:before {
  content: "\e601";
}

.icon-xinlangweibo:before {
  content: "\e600";
}

.icon-weixin:before {
  content: "\e632";
}

.icon-zhihu1:before {
  content: "\e6ba";
}

.icon-xiazai16:before {
  content: "\e610";
}

.icon-linkedin1:before {
  content: "\e696";
}

.icon-youjiantou:before {
  content: "\e624";
}

.icon-zuojiantou:before {
  content: "\e625";
}

.icon-jinrujiantouxiao:before {
  content: "\e8d4";
}

.icon-youxiang:before {
  content: "\e908";
}

.icon-guanbi:before {
  content: "\eaf2";
}

.icon-xiangshang:before {
  content: "\e6ca";
}

.icon-jinritoutiao:before {
  content: "\e68d";
}

.icon-sousuo:before {
  content: "\e60f";
}

.icon-jinrujiantouleft:before {
  content: "\eaf3";
}

.icon-zhima-jianjudingwei:before {
  content: "\e611";
}

